<template>
    <div class="card-align">
        <div class="card">
            <div class="card-body">

                <div class="meetup-header ">
                    <div class="my-auto text-icon-align">
                        <span class=" pointer algin-right">
                            <span @click="editViewDetails()" class="icon-box-new-edit">
                                <feather-icon class=" rotate" icon="EditIcon" size="20" />
                            </span>
                            <span @click="updateDetailsView()" v-if="editableValue">
                                <button class="btn pointer btn-primary btn-sm margin-right" :disabled="!this.$store.state.auth.permissions.includes('asset_category-update')">update</button>
                            </span>
                        </span>
                        <div id="assetname" :class="[{'bordereditable': editableValue}, 'font-Weight-700']"
                            :contenteditable="editableValue">
                            <h4>{{currentDataAsset.name}}</h4>
                        </div>
                        <div id="assetdescription" :class="[{'textarea-editable': editableValue}]"
                            :contenteditable="editableValue">
                            <p>{{currentDataAsset.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card width-25">
            <div class="card-body">
                <div class="flex">
                    <div class="mr-50">
                        <p class="card-text">
                        <h5 class="mb-75 font-weiht">
                            <span class="timeline-item-point-2"></span>
                            Vulnerabilities

                        </h5>
                        <div v-for="value in currentDataVulnerability">
                            <feather-icon class="mr-right text-primary rotate" icon="CheckCircleIcon" size="15" />
                            {{value.name}}
                        </div>
                        </p>
                    </div>
                </div>
                 <div class="flex">
                    <div class="mr-50">
                        
                        <h5 class="mb-75 font-weiht">
                            <span class="timeline-item-point-2"></span>
                            Risks

                        </h5>
                        <div v-for="risk in risks" :key="risk._id" >
                            <feather-icon class="mr-right text-primary rotate" icon="CheckCircleIcon" size="15" />
                            {{risk.name}}
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
        <div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, VBToggle, BPagination, BSidebar } from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  data: () => ({
    detailID:'',
    currentData: [],
    vulnerabilities:[],
    currentDataVulnerability: [],
    currentDataAsset:[],
    assetsDescription: '',
    assetName:'',
    hideSideBar: false,
    editAssetCategories: false,
    createdDay: '',
    editableValue: false,
    risks:[]
  }),
components: {
vSelect,
// BSidebar,
BFormTextarea
},
props: {
   
},
mounted(){
    this.detailID = this.$route.params.id
    this.getAssets(this.detailID)
    // this.currentDate()
},
methods: {
    updateDetailsView() {
        var name = document.getElementById("assetname").innerText;
        var decription = document.getElementById("assetdescription").innerText;
        this.assetName = name
        this.assetsDescription = decription
          let data = {
            name: this.assetName,
            description: this.assetsDescription,
        };
        const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories/${this.detailID}`,
        };
        this.$http(options)
            .then((res) => {
                this.assetName = ''
                this.assetsDescription =''
              this.editableValue = false
            })
            .catch((err) => {
                console.log(err);
            });
    },
    currentDate(date){
        this.createdDay = date.created_at.split('T')[0].split('-')[2]
    },
    editViewDetails() {
        this.editableValue = true
    },
    editRoute () {
     this.hideSideBar = true,
     this.editAssetCategories = true
     this.assetName = this.currentDataAsset.name,
     this.assetsDescription = this.currentDataAsset.description
        
    },
        cancel () {
        this.hideSideBar = false
        this.assetName = ''
        this.assetsDescription =''
    },
        updateAsset(){
         let data = {
            name: this.assetName,
            description: this.assetsDescription,
        };
        const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories/${this.detailID}`,
        };
        this.$http(options)
            .then((res) => {
                this.getAssets(this.detailID)
                this.assetName = ''
                this.assetsDescription =''
                this.editAssetCategories = false,
                this.hideSideBar = false
            })
            .catch((err) => {
                console.log(err);
            });

    },
    getAssets(id) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/ast_categories/${id}`,
        };
        this.$http(options)
            .then((res) => {
                this.currentData = res.data.data.data
                this.currentDataAsset = res.data.data.asset
                this.currentDate(this.currentDataAsset)
                this.currentDataVulnerability = res.data.data.vulnerabilities,
                this.risks = res.data.data.risks
            })
            .catch((err) => {
                console.log(err);
            });
        
    },
}

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.textarea-editable {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    border: 1px solid #d1cdcd;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    height: 65px;
    overflow: auto;
    padding: 2px;
    resize: both;
    width: 400px;
    border-radius: 5px;
}
.text-icon-align {
    float:right;
    width:100%
}
.timeline-item-point-2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: yellow;
    display: inline-flex;
    margin-right: 5px;
}
.bordereditable {
    border: 1px solid #d1cdcd;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 400px;
    // height: 35px;

}
.padding-bottom-20 {
    padding-bottom: 1.5rem;
}
.flex-inline{
    display: inline-flex;
    margin-right: 5px;
}
.mr-right {
    margin-right: 5px;
}
.card-design {
    display: flex;
    justify-content: space-between;
}
// .mr-50{
//     margin-right: 100px !important;
// }
.flex {
    display: flex;
}
.algin-right {
float: right;
align-items: center;
display: flex
}
.font-Weight-700 {
    font-weight: 700;
}
.icon-box-new-edit {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
} 

.meetup-day {
    border-right: 1px solid #d8d6de;
    padding-right: 1.3rem;
    margin-right: 1.3rem;
    text-align: center;
}
.font-weiht {
    font-weight: 800;
}
.padding-20 {
    padding: 20px;
}
.editable {
    outline: none;
}
.card .card-body {
    padding-bottom: 20px !important;
}
</style>
